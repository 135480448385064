import "./users.css"

import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "@/templates/layout"
import { Helmet } from "react-helmet"

const UserCard = function (props: { user: any }) {
  const { slug, name, avatar } = props.user
  const url = avatar.url.replace("data.madcrypto.com", "www.madcrypto.com")

  return <Link to={`/authors/${slug}/`} className="user-card">
    <img className="author-avatar" src={url} width={avatar.size} height={avatar.size} />
    <div className="author">
      <div className="author-name">
        {name}
      </div>
    </div>
  </Link>
}

const Index = (props: any) => {
  const { data } = props
  const users = data.users.nodes

  const seo = {
    title: "Authors | Madcrypto",
  }

  return (
    <Layout {...props} seo={seo} title={`Authors`}>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <ol className="users">
        {users.map((user: any, i: number) => <UserCard user={user} key={i} />)}
      </ol>
    </Layout>
  )
}

export default Index

export const archiveQuery = graphql`
  query archiveQuery {
    users: allWpUser {
      nodes {
        avatar {
          size
          url
        }
        slug
        name
      }
    }
  }
`